import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CounterpartiesFeature } from './selectors';
import { PartyDto } from '../services/parties-api.service';

export const CounterpartiesActions = createActionGroup({
  source: CounterpartiesFeature,
  events: {
    'Get Counterparties': emptyProps(),
    'Load Counterparties Fail': emptyProps(),
    'Load Counterparties': emptyProps(),
    'Set Counterparties': props<{ counterparties: PartyDto[] }>(),
    'Create Counterparty': props<{ model: PartyDto }>(),
    'Create Counterparty Success': props<{ counterparty: PartyDto }>(),
    'Create Counterparty Fail': emptyProps(),
    'Create Counterparty Cancel': props<{ pristine: boolean }>(),
    'Create Counterparty Cancelled': emptyProps(),
    'Clear Create Counterparty Cancelled': emptyProps(),
    'Close Creating Counterparty Modal': emptyProps(),
    'Delete Counterparty': props<{ id: string }>(),
    'Delete Counterparty Confirmed': props<{ id: string }>(),
    'Delete Counterparty Success': emptyProps(),
    'Delete Counterparty Fail': emptyProps(),
    'Delete Counterparty Cancel': emptyProps(),
    'Delete Counterparties': props<{ ids: string[] }>(),
    'Delete Counterparties Confirmed': props<{ ids: string[] }>(),
    'Delete Counterparties Success': emptyProps(),
    'Delete Counterparties Fail': emptyProps(),
    'Delete Counterparties Cancel': emptyProps(),
    'Load Counterparty': props<{ id: string }>(),
    'Load Counterparty Fail': emptyProps(),
    'Set Counterparty': props<{ counterparty: PartyDto }>(),
    'Edit Counterparty': props<{ model: PartyDto; id: string }>(),
    'Edit Counterparty Success': props<{ counterparty: PartyDto }>(),
    'Edit Counterparty Fail': emptyProps(),
    'Edit Counterparty Cancel': props<{ pristine: boolean }>(),
    'Edit Counterparty Cancelled': emptyProps(),
    'Clear Edit Counterparty Cancelled': emptyProps(),
    'Close Editing Counterparty Modal': emptyProps(),
  },
});
